

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createUser } from "../../../actions/masters/userActions";
import UserForm from "./UserForm";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { fetchLDAPUser } from "../../../actions/masters/LDAPActions";
import { fetchRoles } from "../../../actions/masters/roleActions";
import history from "../../../history";
import jwt_decode from "jwt-decode";

class UserCreate extends Component {
  onSearch = () => {
    this.props.fetchLDAPUser(this.props.employeeSearchValue);
    this.props.fetchRoles();
  };

  onSubmit = (formValues) => {
    this.props.createUser(formValues);
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>;
    }
  }

  renderInput = ({ input, label, meta, disabled }) => {
    return (
      <div className="mb-5">
        <div className="w-50 m-auto userSearchDiv">
          <div className="form-group">
            {/* <label className="form_label">
            {label}
          </label> */}
            <div className="input-field">
              <input
                {...input}
                type="text"
                autoComplete="off"
                placeholder={label}
                className="form-control"
                disabled={disabled}
              />
            </div>
            <button className="btnMain">Search</button>
            <div className="error">{this.renderError(meta)}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
   
    //Added By Mayur
    try{
    const { sub, role } = jwt_decode(localStorage.getItem("userToken"));    
    if(role !== "Admin"){
      history.push("/acessforbidden");
    }}
    catch{
      history.push("/acessforbidden");
    }
 //End
    return (
      <div className="BodyInContainer">
        <div className="d-block clearfix">
          <div className="Header-Content float-start">
            <h3 className="pageMainTitle">Add Internal User</h3>
          </div>
          <div className="float-end mt-15">
            <Link
              className="float-end cardBackBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Link>
          </div>
        </div>
        <div className="mainBodyCard pt-30">
          <form onSubmit={this.props.handleSubmit(this.onSearch)}>
            <div>
              <Field
                name="employeeID"
                component={this.renderInput}
                label="Search"
              />
            </div>
          </form>
          {/* <hr /> */}

          <UserForm
            onSubmit={this.onSubmit}
            initialValues={this.props.initialValues}
            roles={this.props.roles.filter(
              (role) =>
                parseInt(role.role_Id) !== 5 && parseInt(role.role_Id) !== 7
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("UserCreateForm");
  const employeeSearchValue = selector(state, "employeeID");
  const initialValues = {};
  if (state.LDAPUser.user !== undefined) {
    initialValues.user_id = state.LDAPUser.user.userId;
    initialValues.user_name = state.LDAPUser.user.userName;
    initialValues.email_id = state.LDAPUser.user.userEmail;
  }
  return {
    employeeSearchValue,
    LDAPUser: Object.values(state.LDAPUser),
    roles: Object.values(state.role),
    initialValues,
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.employeeID) {
    errors.employeeID = "This Field is Required";
  }
  return errors;
};

const userCreateForm = reduxForm({
  form: "UserCreateForm",
  enableReinitialize: true,
  validate,
})(UserCreate);

export default connect(mapStateToProps, {
  createUser,
  fetchLDAPUser,
  fetchRoles,
})(userCreateForm);
